<template>
  <div v-if="!loading" class="flex h-full w-full">
    <SidebarNft @select:chain="setSelectedChain($event)" />
    <div class="relative h-full w-full overflow-y-auto text-white">
      <slot />
    </div>
  </div>
  <div v-else class="flex h-full w-full items-center justify-center">
    <BaseLoader class="h-10 w-10" />
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { ref, provide, onMounted, onUnmounted } from 'vue';
import SidebarNft from '@/components/sidebar/SidebarNft.vue';
import useHttp from '@/composeables/http';
import { flashMessage } from '@/composeables/helpers';
import { useLocalStorage } from '@vueuse/core';
import useEmitter from '@/composeables/emitter';

const $store = useStore();
const $http = useHttp();
const $emitter = useEmitter();
const nftChain = useLocalStorage('nftChain', 'ethereum');

// USER COLLECTIONS
const userCollections = ref([]);

const addUserCollection = async e => {
  const index = userCollections.value.findIndex(c => c.contract === e.contract);
  if (index !== -1) {
    let recordId = userCollections.value[index].id;
    // remove the collection
    userCollections.value.splice(index, 1);
    await $http.delete(`/nft_collections/${recordId}`, e).then(() => {
      flashMessage({
        type: 'success',
        message: 'Successfully removed from watchlist'
      });
    });
  } else {
    // add the collection
    let response = await $http.post('/nft_collections', e);
    userCollections.value.push(response.data);
    flashMessage({
      type: 'success',
      message: 'Successfully added to watchlist'
    });
  }
};

// USER TOKEN
const userTokens = ref([]);

const addUserToken = async e => {
  const index = userTokens.value.findIndex(c => c.contract === e.contract && e.token === c.token);

  if (index !== -1) {
    let recordId = userTokens.value[index].id;
    userTokens.value.splice(index, 1);
    await $http.delete(`/nft_tokens/${recordId}`, e);
  } else {
    let response = await $http.post('/nft_tokens', e);
    userTokens.value.push(response.data);
  }
};

// LOAD DATA
const loading = ref(false);

const fetchData = async () => {
  loading.value = true;

  const collectionsResponse = await $http.get('/nft_collections');
  userCollections.value = collectionsResponse.data;

  const tokensResponse = await $http.get('/data/nft_tokens');
  userTokens.value = tokensResponse.data;

  loading.value = false;
};

onMounted(async () => {
  await fetchData();
});

// CHAIN SELECTION
const setSelectedChain = chain => {
  nftChain.value = chain;
};

onMounted(() => {
  $emitter.$on('select:chain', setSelectedChain);
});

onUnmounted(() => {
  $emitter.$off('select:chain');
});

provide('addUserCollection', addUserCollection);
provide('addUserToken', addUserToken);
provide('userCollections', userCollections);
provide('userTokens', userTokens);
</script>
